export const useAnchorScroll = () => {
  const router = useRouter();

  const navigateToSection = async (targetPath: string, targetHash: string) => {
    const currentPath = router.currentRoute.value.path;
    const hashOnly = targetHash.startsWith("#") ? targetHash : `#${targetHash}`;

    // If we're already on the target page, just scroll
    if (currentPath === targetPath) {
      scrollToElement(hashOnly.substring(1));
    } else {
      // Navigate to the page first, then scroll after navigation
      await router.push({
        path: targetPath,
        hash: hashOnly,
      });

      // After navigation completes, scroll to the element
      setTimeout(() => {
        scrollToElement(hashOnly.substring(1));
      }, 100); // Small delay to ensure DOM is updated
    }
  };

  const scrollToElement = (elementId: string) => {
    if (!process.client) return;

    nextTick(() => {
      const el = document.getElementById(elementId);
      if (!el) return;

      const headerOffset = 80; // Adjust based on your header height
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    });
  };

  return { navigateToSection, scrollToElement };
};
